html {
    font-family: Georgia, serif;
}

.root-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.haiku-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0px;
}

.haiku {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0px;
    font-family: Georgia, serif;
    font-size: 12px;
    font-weight: bold;
}

.birthday-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.birthday-header {
    font-size: 48px;
    text-align: center;
}

.aparekh {
    width: 90%;
}

.info-header {
    text-align: center;
}

.password-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Georgia, serif;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 0px 5px 0px;
}

.letter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}